
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import ReceiptsTable from "@/components/reusable/ReceiptsTable.vue";
import InvoicesTable from "@/components/reusable/InvoicesTable.vue";
import PolicyDetailsTable from "@/components/reusable/PolicyDetailsTable.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "admin-client-policy-information",
  components: {
    ReceiptsTable,
    InvoicesTable,
    PolicyDetailsTable,
    GoBackButton,
  },
  props: ["publicId"],
  data() {
    return {
      policySummary: {
        premium: 0,
        invoiceData: {
          premium: 0,
        },
      },
      policyDetails: [],
      receipts: [],
      invoices: [],
      premiumAmount: 0 as number,
      // publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Policy Information", ["Policy"]);
    });

    /**
     * Reset config
     * @param event
     */
    const reset = (event) => {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      reset,
      setActiveTab,
    };
  },
  created() {
    // Get policy information
    this.getPolicyInformation();

    //Set page title
    document.title = "Policy Information | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getPolicyInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.policyDetails = data.data.items;
            this.receipts = data.data.receipts;
            this.invoices = data.data.invoices;
            this.premiumAmount =
              this.policySummary?.premium ??
              this.policySummary?.invoiceData?.premium;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
  },
});
